import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import bentoBagPcImg from '@/assets/img/bento-bag-pc.svg'
import chatImg from '@/assets/img/chat.svg'
import safetyImg from '@/assets/img/safety.svg'
import shapeRightImg from '@/assets/img/shape-bg-right.svg'
import { env } from '@/env'

import Button from '../Button'
import * as S from './styles'

export type ErrorPageProps = {
  variant?: string | number
  link?: string
  error?: string
  onClick?: () => void
}

type ButtonTryAgainProps = Pick<ErrorPageProps, 'link' | 'onClick'>

const ButtonTryAgain = ({ onClick }: ButtonTryAgainProps) => {
  const navigate = useNavigate()
  const hasAction = !!onClick
  const buttonText = hasAction ? 'Try again' : 'Back to initial page'

  const handleClick = () => {
    if (hasAction) {
      onClick()
      return
    }

    navigate('/')
  }

  return <Button onClick={handleClick}>{buttonText}</Button>
}

const ErrorPage = ({ onClick, variant, error }: ErrorPageProps) => {
  const is404Error = variant === 404

  useEffect(() => {
    Sentry.addBreadcrumb({
      category: 'error',
      message: 'Something went wrong',
      level: 'error',
    })
  }, [])

  if (is404Error) {
    return (
      <S.Wrapper variant={variant}>
        <S.ShapeRight imageUrl={shapeRightImg}>
          <S.Text>
            <h3>Well, this is unexpected…</h3>
            <h2>Page not found</h2>

            <S.Number>404</S.Number>

            <ButtonTryAgain onClick={onClick} />
          </S.Text>

          <S.Images>
            <S.ImageSafety src={safetyImg} alt="Image Safety" />
            <S.ImageBagRight src={bentoBagPcImg} alt="Bento Bag PC" />
            <S.ImageChat src={chatImg} alt="Image Chat" />
          </S.Images>
        </S.ShapeRight>
      </S.Wrapper>
    )
  }

  return (
    <S.Wrapper variant={variant}>
      <S.ShapeRight imageUrl={shapeRightImg}>
        <S.Text>
          <h3>…OOPS</h3>
          <h2>ERROR</h2>

          {env.VITE_ENV === 'stage' && (
            <small className="mb-4 max-w-[25rem] rounded-[12px] bg-grayscale-darker p-8 font-semibold text-grayscale-lightest">
              Error: {error}
            </small>
          )}

          <ButtonTryAgain onClick={onClick} />
        </S.Text>

        <S.Images>
          <S.ImageSafety src={safetyImg} alt="Image Safety" />
          <S.ImageBagRight src={bentoBagPcImg} alt="Bento Bag PC" />
          <S.ImageChat src={chatImg} alt="Image Chat" />
        </S.Images>
      </S.ShapeRight>
    </S.Wrapper>
  )
}
export default ErrorPage
