import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import Error500 from '@/components/Error500'

export function Error500Page() {
  useEffect(() => {
    Sentry.addBreadcrumb({
      category: 'error',
      message: 'Something went wrong',
      level: 'error',
    })
  }, [])

  return (
    <>
      <Helmet title="500" />
      <Error500 />
    </>
  )
}
